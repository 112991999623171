








































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";
import Rules from "@/app/modules/_global/classes/Rules";

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    ReservationCreateForm: () =>
      import("@/app/modules/booking/components/ReservationCreateForm.vue")
  }
})
export default class CreateReservationPage extends Vue {
  @Prop(Number) readonly tileId!: number;

  valid: boolean = true as boolean;
  errors: object = {} as object;

  formData = {};

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(this.resource?.contentable?.title);
  }

  beforeDestroy() {
    this.scrollTop();
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get resource() {
    let filteredTile;

    const recursiveFilter = (filter, tiles) => {
      return tiles.filter(tile => {
        if (tile.id == this.tileId) filteredTile = tile;
        if (tile.children) recursiveFilter(filter, tile.children);
      });
    };

    recursiveFilter(tile => tile.id == this.tileId, this.appData.tiles);

    return filteredTile;
  }

  book() {
    const data = {
      person_id: this.userData.person.id,
      resource_id: this.resource.contentable_id,
      ...this.formData
    };

    this.$store
      .dispatch("booking/storeReservation", data)
      .then(() => this.$router.push({ name: "booking.reservations" }))
      .catch(error => (this.errors = error.response.data.errors));
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
