import moment from "moment";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;

export default class Rules {
  static required(v): boolean {
    return v != undefined && v != "";
  }

  static email(v): boolean {
    return !Rules.required(v) || EMAIL_REGEX.test(String(v).toLowerCase());
  }

  static date(v): boolean {
    if (v) {
      const date = moment(v, "DD.MM.YYYY", true);
      return date.isValid();
    }

    return true;
  }

  static birthdate(v): boolean {
    if (v) {
      const date = moment(v, "DD.MM.YYYY", true);
      return (
        date.isValid() && date.isBefore(moment()) && date.isAfter(moment().subtract(100, "years"))
      );
    }

    return true;
  }
}
